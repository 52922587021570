import $store from 'STORE'
import localforage from 'localforage'
import { systemSettings, systemSettingsDefault } from '@/config/system-settings'

export const toggleBodyTheme = async newTheme => {
  const config = await localforage.getItem('systemConfig')
  let systemConfig = JSON.parse(config)

  if (!systemConfig) {
    systemConfig = {}
    for (let key in systemSettings) {
      systemConfig[systemSettings[key]] =
        systemSettingsDefault[systemSettings[key]]
    }
  }

  // TODO: all fn rewrite

  const theme = systemConfig?.theme

  if (newTheme) {
    systemConfig.theme = newTheme
    const config = JSON.stringify(systemConfig)
    await localforage.setItem('systemConfig', config)
  }

  document.body.classList.remove(getTheme(theme))
  document.body.classList.add(getTheme(newTheme || theme))
}

export const getTheme = value => {
  const theme = value || $store.getters.getTheme || 'system'

  if (theme === 'system') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }

  return theme
}
