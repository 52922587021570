import $store from 'STORE'
import { flyToGeom } from '@/helpers/map'
import localforage from 'localforage'

export const saveRegion = async ({ city, module }) => {
  const regionConfig = await localforage.getItem('regionConfig')
  const config = JSON.parse(regionConfig)

  await localforage.setItem(
    'regionConfig',
    JSON.stringify({
      ...(config || {}),
      [module]: city
    })
  )

  const commitName = $store.state.storeRelations[module]
  $store.commit(commitName, ['city', city])
}

export const setCity = async ({ mapgl, city, module }) => {
  const availableCities = $store.state.availableCities
  const defaultCity = $store.state[module].city

  if (!availableCities.length) return

  const currentCity = city || defaultCity || availableCities[0]?.name

  if (mapgl) {
    const { bbox } = availableCities.find(item => item.name === currentCity)

    if (bbox) {
      flyToGeom({ mapgl, bbox, speed: 2 })
    } else {
      console.log('There is no bbox')
    }
  }

  await saveRegion({ city: currentCity, module })

  return currentCity
}
